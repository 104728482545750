import { CardForm } from './components/CardForm/CardForm';
import './WebViewComponents.scss';

export const WebViewComponents = () => {
    return (
        <div className='root'>
            <h1 className='header-title'>Specify the debit card</h1>
            <CardForm />
        </div>
    );
};
