import './SubmitButton.scss';
import { useFormContext } from 'react-hook-form';
import { Inputs } from '../CardForm/CardForm';
export const SubmitButton = () => {
    const { formState } = useFormContext<Inputs>();
    const isDirty = Object.keys(formState.dirtyFields).length > 0;
    const isValid = !Object.keys(formState.errors).length;

    return (
        <div className='top-up-container'>
            <button
                className={
                    isValid && isDirty ? 'top-up-button-light' : 'top-up-button'
                }
                type='submit'
                disabled={!isValid || !isDirty}
            >
                Top up
            </button>
        </div>
    );
};
