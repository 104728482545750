import { ChangeEvent, useEffect, useState } from 'react';
import './CardNumberInput.css';
import { Controller, useFormContext } from 'react-hook-form';
import { Inputs } from '../CardForm/CardForm';

export const CardNumberInput = () => {
    const {
        control,
        setValue,
        formState,
        getFieldState,
        setError,
        clearErrors,
    } = useFormContext<Inputs>();

    const [cardNumber, setCardNumber] = useState<string>('');
    const isInvalid =
        getFieldState && getFieldState('cardNumber', formState).invalid;
    const isDirty =
        getFieldState && getFieldState('cardNumber', formState).isDirty;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const cardCode = event.target.value.replace(/\D/g, '').substring(0, 16);
        const formattedCardNumber = cardCode.replace(/(\d{4})(?=\d)/g, '$1 ');
        setCardNumber(formattedCardNumber);
        setValue('cardNumber', formattedCardNumber, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    useEffect(() => {
        if (cardNumber.length < 19) {
            setError('cardNumber', { message: 'err' });
        } else {
            clearErrors('cardNumber');
        }
    }, [isInvalid]);

    return (
        <Controller
            control={control}
            name={'cardNumber'}
            rules={{ required: true, minLength: 19 }}
            render={({ field }) => (
                <div className='card-input-root'>
                    <input
                        className={
                            isInvalid && isDirty
                                ? 'card-input-error'
                                : 'card-input'
                        }
                        type='text'
                        value={cardNumber}
                        onChange={handleChange}
                        placeholder={'0000 0000 0000 0000'}
                    />
                    <span className='card-error'>
                        {isInvalid ? 'At least 16 digits' : ''}
                    </span>
                </div>
            )}
        />
    );
};
