import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CardNumberInput } from '../CardNumberInput/CardNumberInput';
import { DateMonthInput } from '../DateMonthInput/DateMonthInput';
import { CodeInput } from '../CodeInput/CodeInput';
import './CardForm.scss';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SubmitButton } from '../SubmitButton/SubmitButton';
import { generateRandomString } from '../../../utils/randomString';

const RETURN_SUCCESS_URL =
    'https://dev.robotpay.redmadrobot.com/rs/return-success';

export type Inputs = {
    cardNumber: string;
    date: string;
    code: string;
};
export const CardForm = () => {
    const methods = useForm<Inputs>({ mode: 'onChange' });
    const location = useLocation();
    const [rPayTransactionID, setRPayTransactionID] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setRPayTransactionID(queryParams.get('id2') || '');
    }, [location.search]);

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        console.log(data);

        const trans_id = generateRandomString();

        try {
            const response = await fetch(RETURN_SUCCESS_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `id_oper=${encodeURIComponent(
                    rPayTransactionID,
                )}&trans_id=${encodeURIComponent(trans_id)}`,
            });

            window.location.href = RETURN_SUCCESS_URL;
        } catch (error) {
            console.log(error);
            alert('Something went wrong. Try again.');
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className='card-container'>
                    <div className='right-container'>
                        <div className='card-container-form'>
                            <CardNumberInput />
                            <div className='container'>
                                <div className='date-container'>
                                    <DateMonthInput />
                                </div>
                                <div>
                                    <CodeInput />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='left-container'></div>
                </div>
                <SubmitButton />
            </form>
        </FormProvider>
    );
};
