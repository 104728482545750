import React from 'react';
import './App.css';
import { WebViewComponents } from './WebView/WebViewComponents';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
    return (
        <Router>
            <div className='App'>
                <WebViewComponents />
            </div>
        </Router>
    );
}

export default App;
