import './CodeInput.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Inputs } from '../CardForm/CardForm';

export const CodeInput = () => {
    const {
        control,
        setValue,
        formState,
        getFieldState,
        setError,
        clearErrors,
    } = useFormContext<Inputs>();
    const [codeNumber, setCodeNumber] = useState<string>('');
    const isInvalid = getFieldState && getFieldState('code', formState).invalid;
    const isDirty = getFieldState && getFieldState('code', formState).isDirty;
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const cardCode = event.target.value.replace(/\D/g, '').substring(0, 3);
        setCodeNumber(cardCode);
        setValue('code', cardCode, { shouldDirty: true, shouldValidate: true });
    };

    useEffect(() => {
        if (codeNumber.length < 3) {
            setError('code', { message: 'err' });
        } else {
            clearErrors('code');
        }
    }, [isInvalid]);

    return (
        <Controller
            control={control}
            name={'code'}
            rules={{ required: true, minLength: 3 }}
            render={({ field }) => (
                <div className='code-input-root'>
                    <input
                        className={
                            isInvalid && isDirty
                                ? 'code-input-error'
                                : 'code-input'
                        }
                        type='text'
                        value={codeNumber}
                        onChange={handleChange}
                        placeholder={'CVV'}
                    />
                    <p className='code-label'>On the other side</p>
                </div>
            )}
        />
    );
};
