import './DateMonthInput.css';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Inputs } from '../CardForm/CardForm';
export const DateMonthInput = () => {
    const {
        control,
        setValue,
        setError,
        clearErrors,
        formState,
        getFieldState,
    } = useFormContext<Inputs>();

    const [dateInput, setDateInput] = useState<string>('');
    const isInvalid = getFieldState && getFieldState('date', formState).invalid;
    const isDirty = getFieldState && getFieldState('date', formState).isDirty;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const cardCode = event.target.value.replace(/\D/g, '').slice(0, 4);
        const formattedCardDate = cardCode.replace(/(\d{2})(?=\d{1})/g, '$1/');
        setDateInput(formattedCardDate);
        setValue('date', formattedCardDate, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    useEffect(() => {
        if (Number(dateInput.slice(0, 2)) > 12 || dateInput.length < 5) {
            setError('date', { message: 'err' });
        } else {
            clearErrors('date');
        }
    }, [isInvalid]);

    return (
        <Controller
            control={control}
            name={'date'}
            rules={{ required: true, minLength: 5 }}
            render={({ field, fieldState }) => (
                <div className='date-input-root'>
                    <input
                        className={
                            isInvalid && isDirty
                                ? 'date-input-error'
                                : 'date-input'
                        }
                        type='text'
                        placeholder='MM/YY'
                        value={dateInput}
                        onChange={handleChange}
                    />
                </div>
            )}
        />
    );
};
